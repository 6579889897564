import axios from "axios";

export default function check_verify_code(data) {
    return (
        axios({
            method: 'post',
            url: "https://swiitch-bukar.herokuapp.com/api/verify",
            data: data,
        })
    )
}