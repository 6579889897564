/** Importation globale : */
import React from 'react';
import { Link } from 'react-router-dom';
// import {ShareSocial} from 'react-share-social';

import { connect } from 'react-redux';
import AccessStore from '../store/accessStore';

/** Importation du stylesheet : */
import './styles/profile.style.css';

/**Importation des images */
import logout from '../assets/icons/011-logout-1.png';
import swiitch_logo from '../assets/icons/LOGO-SWIITCH-DESIGN1.png';

/**Importation des helpers */


/**Importatation des composants */
import Share from '../components/share_component/share.component';

const SHARE_URL = "https://bit.ly/SentinelleAf";

// const style = {
//     background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
//     borderRadius: 3,
//     border: 0,
//     color: 'white',
//     padding: '0 30px',
//     boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
// };


function mapStateToProps(state) {
    return {
        user_sentinelle : state.User.user_sentinelle,
    };
}
/**
 * Construction du screen.
 */
class Profile extends React.Component {

	constructor(props) {
		super(props);
		this.accessStore = new AccessStore(this.props.dispatch);

		this.state = {
			user: {},
            share: false,
            refcode_copied: false,
		}
	}

	componentDidMount() {
		this.accessStore.callback(async (response) => {
			// console.log( response );
			this.setState({
				user: JSON.parse( response ),
			});
        });

		this.accessStore.init( 'user_sentinelle', "INIT_USER");
        this.timeout = '';
	}

	_copyToClipBoard() {
		var copyText = document.querySelector("#affiliateCode");
        clearTimeout( this.timeout );
  		copyText.select();
		document.execCommand("copy");

        this.setState({
            refcode_copied: true,
        }, () => {
            this.timeout = setTimeout( () => {
                this.setState({
                    refcode_copied: false,
                });
            }, 5000);
        });
	}

	_isObjEmpty(obj) {
		for (var prop in obj) {
		  if (obj.hasOwnProperty(prop)) return false;
		}
		return true;
	}

    _shareSenti() {
        this.setState({
            share: true,
        });
    }

    _cancelShareSenti() {
        this.setState({
            share: false,
        });
    }

    _logout() {
        this.accessStore.setRedux( 'LOG_OUT', {} );
    }

	render() {
		// console.log( (this.props.user_sentinelle) );
		return (
			<div className="Profile">
                {/* <ShareSocial 
                    style={style}
                    url ="https://bit.ly/SentinelleAd"
                    socialTypes={['facebook','twitter','reddit','linkedin', 'messenger']}
                /> */}
				<div className="Profile_content">
					<div className="Profile_content_">
						<div className="Profile_content_noActivities">
							<div className="Profile_content_noActivities_title1">
								<span>
									Bonjour { this.state.user.name ? this.state.user.name : '' }!
								</span>
							</div>

							<div className="Profile_content_noActivities_affiliateCode">
								<div
									className="Profile_content_noActivities_affiliateCode_button"
									onClick={() => {
										this._copyToClipBoard();
									}}
                                    style={{
                                        background: this.state.refcode_copied ? " rgba(37, 174, 136, 0.25)" : "#DCDCDC"
                                    }}
								>
									<div className="Profile_content_noActivities_affiliateCode_button_text">
										<span className="Profile_content_noActivities_affiliateCode_button_text_top">
											Votre code d’affiliation
										</span>
										<input id="affiliateCode" className="Profile_content_noActivities_affiliateCode_button_text_code" readOnly={true} type="text" contentEditable={false} value={ this.state.user.refcode ? this.state.user.refcode : '' }/>
									</div>
									<div>
										<svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.1429 16.625V18.1094C12.1429 18.6013 11.7351 19 11.2321 19H0.910714C0.407734 19 0 18.6013 0 18.1094V4.45312C0 3.96124 0.407734 3.5625 0.910714 3.5625H3.64286V14.5469C3.64286 15.6928 4.59611 16.625 5.76786 16.625H12.1429ZM12.1429 3.85938V0H5.76786C5.26488 0 4.85714 0.39874 4.85714 0.890625V14.5469C4.85714 15.0388 5.26488 15.4375 5.76786 15.4375H16.0893C16.5923 15.4375 17 15.0388 17 14.5469V4.75H13.0536C12.5527 4.75 12.1429 4.34922 12.1429 3.85938ZM16.7333 2.70791L14.231 0.260842C14.0602 0.0938283 13.8286 1.23499e-06 13.5871 0L13.3571 0V3.5625H17V3.33765C17 3.10146 16.9041 2.87493 16.7333 2.70791Z" fill={ this.state.refcode_copied ? " rgba(37, 174, 136, 0.38)" : "#646464" }/>
										</svg>
									</div>
								</div>
							</div>
							<div className="Profile_content_noActivities_clickToCpyText">
                                {
                                    this.state.refcode_copied ?
                                        <span style={{color: "#2E8C72"}}>Code copié!</span>
                                    :
                                        <span>
                                            Cliquez sur le bouton pour copier
                                        </span>
                                }
							</div>
						</div>

                        <div className="Profile_content_menu">
                            <div
                                style={{borderTop: "1px solid rgba(53, 61, 64, 0.2)", marginTop: "20px"}}
                                className="Profile_content_menu_item"
                                onClick={() => {
                                    this._shareSenti();
                                }}
                            >
                                <div className="Profile_content_menu_item_icon">
                                    <svg style={{ marginRight: "10px" }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0)">
                                            <path d="M15.783 13.4262C15.2149 12.902 14.5259 12.4491 13.735 12.0801C13.3969 11.9224 13.0013 12.0823 12.8511 12.4371C12.701 12.792 12.8532 13.2074 13.1912 13.3652C13.8581 13.6764 14.4328 14.0523 14.899 14.4825C15.4739 15.0129 15.8036 15.7808 15.8036 16.5898V17.8906C15.8036 18.2783 15.5031 18.5938 15.1339 18.5938H2.00893C1.63971 18.5938 1.33929 18.2783 1.33929 17.8906V16.5898C1.33929 15.7808 1.66901 15.0129 2.24383 14.4825C2.9204 13.8582 4.89166 12.4062 8.57143 12.4062C11.3038 12.4062 13.5268 10.0721 13.5268 7.20312C13.5268 4.33418 11.3038 2 8.57143 2C5.8391 2 3.61607 4.33418 3.61607 7.20312C3.61607 8.88033 4.37596 10.3745 5.55307 11.3266C3.39922 11.8237 2.05941 12.7806 1.35982 13.4262C0.495693 14.2234 0 15.3764 0 16.5898V17.8906C0 19.0538 0.90114 20 2.00893 20H15.1339C16.2417 20 17.1429 19.0538 17.1429 17.8906V16.5898C17.1429 15.3764 16.6472 14.2234 15.783 13.4262ZM4.95536 7.20312C4.95536 5.10954 6.57754 3.40625 8.57143 3.40625C10.5653 3.40625 12.1875 5.10954 12.1875 7.20312C12.1875 9.29671 10.5653 11 8.57143 11C6.57754 11 4.95536 9.29671 4.95536 7.20312Z" fill="#353D40"/>
                                        </g>
                                        <path d="M15.8335 1.875C15.5048 1.875 15.2383 2.15481 15.2383 2.5C15.2383 2.84519 15.5048 3.125 15.8335 3.125H17.024V4.375C17.024 4.72019 17.2905 5 17.6192 5C17.948 5 18.2145 4.72019 18.2145 4.375V3.125H19.4049C19.7337 3.125 20.0002 2.84519 20.0002 2.5C20.0002 2.15481 19.7337 1.875 19.4049 1.875H18.2145V0.625C18.2145 0.279812 17.948 0 17.6192 0C17.2905 0 17.024 0.279812 17.024 0.625V1.875H15.8335Z" fill="#353D40"/>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="17.1429" height="18" fill="white" transform="translate(0 2)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </div>

                                <div className="Profile_content_menu_item_text">
                                    Inviter un utilisateur
                                </div>
                            </div>

                            <a className="Profile_content_menu_suggestion" href="https://wa.me/+22967979797/?text=">
                                <div className="Profile_content_menu_item">
                                    <div className="Profile_content_menu_item_icon">
                                        <svg style={{ marginRight: "10px" }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.37779 16.0203L2.45988 15.7756L2.30795 15.5669C1.16951 14.0035 0.5 12.0785 0.5 10C0.5 4.76102 4.76001 0.5 9.9975 0.5H10.0025C15.2399 0.5 19.5 4.76223 19.5 10C19.5 15.2378 15.2399 19.5 10.0025 19.5C8.06975 19.5 6.27911 18.9255 4.77738 17.9307L4.57744 17.7982L4.349 17.8712L1.44548 18.7994L2.37779 16.0203Z" stroke="#353D40"/>
                                            <path d="M15.8212 14.1212C15.5799 14.8025 14.6224 15.3675 13.8587 15.5325C13.3362 15.6437 12.6537 15.7325 10.3562 14.78C7.41744 13.5625 5.52494 10.5762 5.37744 10.3825C5.23619 10.1887 4.18994 8.80123 4.18994 7.36623C4.18994 5.93123 4.91869 5.23248 5.21244 4.93248C5.45369 4.68623 5.85244 4.57373 6.23494 4.57373C6.35869 4.57373 6.46994 4.57998 6.56994 4.58498C6.86369 4.59748 7.01119 4.61498 7.20494 5.07873C7.44619 5.65998 8.03369 7.09498 8.10369 7.24248C8.17494 7.38998 8.24619 7.58998 8.14619 7.78373C8.05244 7.98373 7.96994 8.07248 7.82244 8.24248C7.67494 8.41248 7.53494 8.54248 7.38744 8.72498C7.25244 8.88373 7.09994 9.05373 7.26994 9.34748C7.43994 9.63498 8.02744 10.5937 8.89244 11.3637C10.0087 12.3575 10.9137 12.675 11.2374 12.81C11.4787 12.91 11.7662 12.8862 11.9424 12.6987C12.1662 12.4575 12.4424 12.0575 12.7237 11.6637C12.9237 11.3812 13.1762 11.3462 13.4412 11.4462C13.7112 11.54 15.1399 12.2462 15.4337 12.3925C15.7274 12.54 15.9212 12.61 15.9924 12.7337C16.0624 12.8575 16.0624 13.4387 15.8212 14.1212Z" fill="#353D40"/>
                                        </svg>
                                    </div>

                                    <div className="Profile_content_menu_item_text">
                                        Avis et commentaires
                                    </div>
                                </div>
                            </a>

                            <div onClick={() => { this._logout() }} className="Profile_content_menu_item">
                                <div className="Profile_content_menu_item_icon">
                                    <img className="Profile_content_menu_item_icon_img" alt="" src={ logout }/>
                                </div>

                                <div style={{color: "#D75A4A"}} className="Profile_content_menu_item_text">
                                    Me déconnecter
                                </div>
                            </div>
                        </div>

                        <div className="powered_container">
                            <div className="powered">
                                <span>
                                    POWERED BY
                                </span>
                            </div>
                            <div className="swiitch_logo_content">
                                <img className="swiitch_logo" alt="swiitch design" src={ swiitch_logo }/>
                            </div>
                        </div>
					</div>
				</div>
                
                {
                    this.state.share ?
                        <div style={
                            !this.state.share ?{
                                zIndex: -1000,
                                transform: "scale(0)",
                            }
                            : {
                                zIndex: 1000,
                                transform: "scale(1)",
                            }
                        } className="share_container">
                            <div
                                style={{
                                    right: 0,
                                    top: 0,
                                    fontSize: "30px",
                                    color: "#fff",
                                    padding: "20px",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    cursor: "pointer",
                                }}

                                onClick={()=>{
                                    this._cancelShareSenti();
                                }}
                            >
                                <span>✕</span>
                            </div>
                            <div className="share_container_">
                                <Share url={ SHARE_URL } title={ "Hey, je t’invite à découvrir Sentinelle !\nUne superbe application gratuite qui va te préserver du pire.\n\nSentinelle te rappelle les dates d’expiration de toutes les pièces de ton automobile.\n\nTélécharge :  https://bit.ly/SentinelleAf\n Inscris-toi en utilisant mon code de parrainage :  " + ( this.state.user.refcode ? this.state.user.refcode : '' ) }/>
                            </div>
                        </div>
                    :
                        null
                }
			</div>
		);

	}

}

Profile = connect(mapStateToProps, null)(Profile);
export default Profile;