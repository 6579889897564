/** Importation globale : */
import React from 'react';
import { Link } from 'react-router-dom';

/** Importation du stylesheet : */
import './styles/createAccount.style.css';

import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import AccessStore from '../store/accessStore';

/**Importation des images */

/**Importation des helpers */
import Get_country_code from '../helpers/getCountryCode.helper';
import check_verify_code from '../helpers/check_verify_code.helper';

function mapStateToProps(state) {
    return {
        user_sentinelle : state.User.user_sentinelle,
    };

}

/**
 * Construction du screen.
 */
class CreateAccount extends React.Component {

	constructor(props) {
		super(props);
		this.accessStore = new AccessStore(this.props.dispatch);

		this.state = {
			isLoading: false,
			confirm_code: false,
			phone_number: '',
			name: '',
			user: {},
		}
	}

	componentDidMount() {
		// console.log( this.props.user_sentinelle );
		this.accessStore.init( 'user_sentinelle', "INIT_USER");


		// console.log( localStorage.getItem("user_sentinelle") );
	}

	_sendVerificationCode( phone_number, country_code, name ) {
		this.setState({
			isLoading: true,
		}, async () => {
			let data = {
				phone_number: phone_number,
				country_code: country_code,
			};

			check_verify_code( data ).then(( response) => {
				if( response.data.status === 200 ) {
					this.setState({
						isLoading: false,
					}, () => {
						let user = {
							name: name,
							phone_number: phone_number,
							country_code:  country_code,
						};

						this.setState({
							user: user,
						},() => {
							this.setState({
								confirm_code: true,
							});
						});
					})
				}

				else {
					this.setState({
						isLoading: false,
					});
					alert('Erreur', 'Numéro de téléphone incorrecte' );
				}
			}).catch((e) => {
				this.setState({
					isLoading: false,
				});
				if( e.response.status === 500 ) {
					alert('', 'Service en maintenance...' );
				}
				if( e.toString() === "Error: Network Error" ) {
				    alert('', 'Un problème de connexion est survenu. Vérifiez que votre téléphone est bien connectés à Internet' );
				}
			});
		});
	}
	_registered( number ) {
		if( number !== '' ) {
			Get_country_code().then(( response ) => {
				let country_code  = response.data.country_calling_code.split("+")[1];
				let phone_number  = number;
				let name = this.state.name;

				this._sendVerificationCode( phone_number, country_code, name );
				
			}).catch((e) => {
				console.log( e );
			})
		}

		else {
			alert("Imformations incompletes", "Veuillez remplir votre nom et votre numéro de téléphone svp!");
		}
	}

	_isObjEmpty(obj) {
		for (var prop in obj) {
		  if (obj.hasOwnProperty(prop)) return false;
		}
		return true;
	}

	render() {
		// console.log( this.props.user_sentinelle );
		return (
			this._isObjEmpty( this.props.user_sentinelle ) ?
				this.state.confirm_code ?
					<Redirect
						to={{
							pathname: "/enter_confirmation_code",
							state: {
								type: "createAccount",
								user: this.state.user,	
							}
						}}
					/>
				:
					<div className="content">
						<div className="content_">
							<div className="content_top">
								<div className="content_top_icon">
									<svg width="35" height="35" viewBox="0 0 41 45" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M20.4756 25.3186C26.8267 25.3186 31.9794 19.6865 31.9794 12.7447C31.9794 5.8028 26.8267 0.170731 20.4756 0.170731C14.1245 0.170731 8.9718 5.8028 8.9718 12.7447C8.9718 19.6865 14.1245 25.3186 20.4756 25.3186ZM30.7012 28.1128H26.2994C24.5259 29.0035 22.5527 29.5099 20.4756 29.5099C18.3985 29.5099 16.4333 29.0035 14.6518 28.1128H10.25C4.60195 28.1128 0.0243874 33.1162 0.0243874 39.2896V40.6867C0.0243874 43.0007 1.74197 44.878 3.85899 44.878H37.0922C39.2092 44.878 40.9268 43.0007 40.9268 40.6867V39.2896C40.9268 33.1162 36.3493 28.1128 30.7012 28.1128Z" fill="#353D40" />
									</svg>
								</div>

								<div className="content_top_text">
									<span className="content_top_text_">
										Inscrivez-vous
									</span><br />
									<span className="content_top_text_">
										pour continuer
									</span>
								</div>
							</div>

							<div className="content_body">
								<div className="content_body_">
									<div className="content_body_input">
										<span className="content_body_input_label">
											Votre prénom
										</span>
										<input
											className="content_body_input_balise"
											type="text"
											placeholder="Exemple : Fifamè"
											onChange = {( e ) => {
												this.setState({
													name: e.target.value,
												});
											}}
										/>
									</div>

									<div className="content_body_input">
										<span className="content_body_input_label">
											Votre numéro de téléphone
										</span>
										<input
											className="content_body_input_balise"
											type="number"
											placeholder="Exemple : xxxxxxxx"
											onChange = {( e ) => {
												this.setState({
													phone_number: e.target.value,
												});
											}}
										/>
										<span className="content_body_input_subLabel">
											Vous recevrez un code de vérification par SMS
										</span>
									</div>

									<div className="content_body_input">
										<input
											className="content_body_input_balise submit"
											type="submit"
											value="Créer un compte"
											onClick={() => {
												this._registered( this.state.phone_number, this.state.name );
											}}
										/>
									</div>

									<div className="content_body_input2">
										<Link to="/login">
											<input className="content_body_input_connection" type="submit" value="Se connecter" />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
			:
				<Redirect to="/dashboard"/>
		);

	}

}
CreateAccount = connect(mapStateToProps, null)(CreateAccount);

export default CreateAccount;