/** Importation globale : */
import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';

/** Importation du stylesheet : */
import './styles/home.style.css';

import { connect } from 'react-redux';
import AccessStore from '../store/accessStore';

/**Importation des images */

/**Importation des helpers */

/**Importation des screens */
import Dashboard from './dashboard.screen';
import Profile from './profile.screen';


function mapStateToProps(state) {
    return {
        user_sentinelle : state.User.user_sentinelle,
    };
}

/**
 * Construction du screen.
 */
class Home extends React.Component {

	constructor(props) {
		super(props);
		this.accessStore = new AccessStore(this.props.dispatch);

		this.state = {
            headerText: "Tableau de bord",
            current_screen : 0,
		}
	}

	componentDidMount() {

	}

    _view_profile() {
        this.setState({
            current_screen: 1,
            headerText: "Mon profil"
        });
    }

    _view_dashboard() {
        this.setState({
            current_screen: 0,
            headerText: "Tableau de bord"
        });
    }

    _isObjEmpty(obj) {
		for (var prop in obj) {
		  if (obj.hasOwnProperty(prop)) return false;
		}
		return true;
	}

	render() {
		return (
            !this._isObjEmpty( this.props.user_sentinelle ) ?
                <div className="content">
                    <div className="content_">
                        <div className="content_header">
                            <div className="content_header_top">
                                <div className="content_header_top_sentinelleLogo">
                                    {
                                        this.state.current_screen === 0 ?
                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.948 14.7593L19.948 17.6017C24.5008 17.6372 28.2356 21.3679 28.2356 25.9513C28.2356 30.5702 24.4653 34.3008 19.8769 34.3008C17.4227 34.3008 15.1818 33.2349 13.6524 31.5295C12.1229 31.4229 10.7358 30.961 9.45528 30.2149C11.127 34.3008 15.1463 37.1788 19.8413 37.1788C26.0303 37.1788 31.0811 32.1335 31.0811 25.9513C31.0811 19.8046 26.1014 14.7948 19.948 14.7593Z" fill="#EC991C"/>
                                                <path d="M20.0189 25.2407L20.0189 22.3983C15.4661 22.3628 11.7314 18.6321 11.7314 14.0487C11.7314 9.42983 15.5016 5.69917 20.09 5.69917C22.5443 5.69917 24.7851 6.76507 26.3145 8.47051C27.8084 8.61263 29.2312 9.07453 30.4761 9.82066C28.8399 5.69917 24.8206 2.82123 20.1256 2.82123C13.9366 2.82123 8.88586 7.8665 8.88586 14.0487C8.88586 20.1954 13.8655 25.2052 20.0189 25.2407Z" fill="#EC991C"/>
                                            </svg>
                                        :
                                            (
                                                this.state.current_screen === 1 ?
                                                    <div
                                                        className="content_header_top_back"
                                                        onClick={() => {
                                                            this._view_dashboard();
                                                        }}
                                                    >
                                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M25.1562 10.4063H3.33648L11.6916 2.44815C12.1291 2.03143 12.1461 1.33909 11.7294 0.901585C11.3132 0.464632 10.6209 0.447132 10.1828 0.863851L0.640937 9.95292C0.228047 10.3664 0 10.9154 0 11.5C0 12.0841 0.228047 12.6337 0.660078 13.0652L10.1834 22.1357C10.395 22.3375 10.6663 22.4375 10.9375 22.4375C11.2262 22.4375 11.515 22.3238 11.7299 22.0979C12.1466 21.6604 12.1297 20.9686 11.6922 20.5519L3.30203 12.5938H25.1562C25.76 12.5938 26.25 12.1038 26.25 11.5C26.25 10.8963 25.76 10.4063 25.1562 10.4063Z" fill="white" fillOpacity="0.4"/>
                                                        </svg>
                                                    </div>
                                                :
                                                    null
                                            )
                                    }
                                </div>

                                {
                                    this.state.current_screen === 0 ?
                                        <div
                                            className="content_header_top_userIcon"
                                            onClick={() => {
                                                this._view_profile();
                                            }}
                                        >
                                            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M26.6996 18.4089C25.7385 17.5643 24.573 16.8347 23.235 16.2402C22.6631 15.9862 21.9938 16.2437 21.7398 16.8154C21.4858 17.3871 21.7434 18.0564 22.3151 18.3106C23.4432 18.812 24.4154 19.4176 25.2042 20.1107C26.1766 20.9652 26.7344 22.2025 26.7344 23.5059V25.6016C26.7344 26.2262 26.2262 26.7344 25.6016 26.7344H3.39844C2.77384 26.7344 2.26562 26.2262 2.26562 25.6016V23.5059C2.26562 22.2025 2.8234 20.9652 3.79581 20.1107C4.94035 19.1049 8.27506 16.7656 14.5 16.7656C19.1222 16.7656 22.8828 13.005 22.8828 8.38281C22.8828 3.76063 19.1222 0 14.5 0C9.87782 0 6.11719 3.76063 6.11719 8.38281C6.11719 11.085 7.40266 13.4922 9.39394 15.0261C5.75035 15.8271 3.48384 17.3688 2.30036 18.4089C0.838547 19.6932 0 21.5509 0 23.5059V25.6016C0 27.4756 1.52443 29 3.39844 29H25.6016C27.4756 29 29 27.4756 29 25.6016V23.5059C29 21.5509 28.1615 19.6932 26.6996 18.4089ZM8.38281 8.38281C8.38281 5.00982 11.127 2.26562 14.5 2.26562C17.873 2.26562 20.6172 5.00982 20.6172 8.38281C20.6172 11.7558 17.873 14.5 14.5 14.5C11.127 14.5 8.38281 11.7558 8.38281 8.38281Z" fill="white"/>
                                            </svg>
                                        </div>
                                    :
                                        null
                                }
                            </div>

                            <div className="content_header_bottom">
                                <div className="content_header_bottom_">
                                    <span className="content_header_bottom_text">
                                        { this.state.headerText }
                                    </span>

                                    <div className="content_header_bottom_barre"></div>
                                </div>
                            </div>
                        </div>

                        <div className="content_body">
                            {
                                this.state.current_screen === 0 ?
                                    <Dashboard/>
                                :
                                    (
                                        this.state.current_screen === 1 ?
                                            <Profile/>
                                        :
                                            null
                                    )
                            }
                        </div>
                    </div>
                </div>
            :
                <Redirect to="/"/>
		);

	}

}
Home = connect(mapStateToProps, null)(Home);
export default Home;