import React from 'react';


import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WeiboShareButton,
    WhatsappShareButton,
  } from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WeiboIcon,
    WhatsappIcon,
} from "react-share";

import './style/style.css';

class Share extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    render() {
        return (
            <div
                className="share"
                style={{
                    backgroundColor: this.props.backgroundColor,
                }}
            >
                <div className="share_">
                    <FacebookShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <FacebookIcon className="shareIcon" size={66} />
                    </FacebookShareButton>

                    <WhatsappShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <WhatsappIcon className="shareIcon" size={66} />
                    </WhatsappShareButton>

                    <TelegramShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <TelegramIcon className="shareIcon" size={66} />
                    </TelegramShareButton>

                    <LinkedinShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <LinkedinIcon className="shareIcon" size={66} />
                    </LinkedinShareButton>

                    <InstapaperShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <InstapaperIcon className="shareIcon" size={66} />
                    </InstapaperShareButton>

                    <TwitterShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <TwitterIcon className="shareIcon" size={66} />
                    </TwitterShareButton>

                    <EmailShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        subject={ this.props.title }
                    >
                        <EmailIcon className="shareIcon" size={66} />
                    </EmailShareButton>

                    <HatenaShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <HatenaIcon size={66} className="shareIcon" />
                    </HatenaShareButton>

                    <LineShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <LineIcon size={66} className="shareIcon"/>
                    </LineShareButton>

                    <LivejournalShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <LivejournalIcon size={66} className="shareIcon" />
                    </LivejournalShareButton>

                    <MailruShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <MailruIcon size={66} className="shareIcon"/>
                    </MailruShareButton>

                    <OKShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <OKIcon size={66} className="shareIcon"/>
                    </OKShareButton>

                    <PocketShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <PocketIcon size={66} className="shareIcon"/>
                    </PocketShareButton>

                    <RedditShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <RedditIcon size={66} className="shareIcon"/>
                    </RedditShareButton>

                    <WeiboShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                    >
                        <WeiboIcon size={66} className="shareIcon"/>
                    </WeiboShareButton>

                    {/* <FacebookMessengerShareButton 
                        url={ this.props.url }
                        quote={"CampersTribe - World is yours to explore"}
                        className="shareButton"
                        title={ this.props.title }
                        appId="3642644692459223"
                        <FacebookMessengerIcon size={36} />
                    </FacebookMessengerShareButton> */}
                </div>
            </div>
        )
    }
}

export default Share;