import React from 'react';

import { Provider } from 'react-redux';

import ReactDOM from 'react-dom';

import './font_style/font.css';

/**Pour forcer une redirection HTTPS */
// import HttpsRedirect from 'react-https-redirect';

import Store from './store/store.config';
import App from './app';

ReactDOM.render(
  // <HttpsRedirect>
    <Provider store={Store}>
      <React.StrictMode>
        <App/>
      </React.StrictMode>
    </Provider>,
  // </HttpsRedirect>
  document.getElementById('app')
);
