import React from 'react';
import { Route } from 'react-router';
import { HashRouter } from 'react-router-dom';

import CreateAccount from "../screens/createAccount.screen";
import Login from '../screens/login.screen';
import ConfirmCode from '../screens/confirmCode.screen';
import Home from '../screens/home.screen';

class Routes extends React.Component {
    render() {
        return (
            <HashRouter>
                <Route path="/" exact component={CreateAccount}/>
                <Route path="/login" exact component={Login}/>
                <Route path="/enter_confirmation_code" exact component={ConfirmCode}/>
                <Route path="/dashboard" exact component={Home}/>
            </HashRouter>
        )
    }
}

export default Routes;
