/** Importation globale : */
import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import AccessStore from '../store/accessStore';

/** Importation du stylesheet : */
import './styles/dashboard.style.css';

/**Importation des images */

/**Importation des helpers */
import Get_dashboard_info from '../helpers/get_dashboard_info.helper';


function mapStateToProps(state) {
    return {
        user_sentinelle : state.User.user_sentinelle,
    };
}
/**
 * Construction du screen.
 */
class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.accessStore = new AccessStore(this.props.dispatch);

		this.state = {
            headerText: "Tableau de bord",
			user: {},
			user_name: '',

			activities: [],
			signup_tarrif: 0,
			cars_tarif:0,
			gain: 0,

			refcode_copied: false,
		}
	}

	componentDidMount() {
		// navigator.permissions.query( {name: "clipboard-write"} ).then((r) => {
		// 	alert( r.state );
		// }).catch((e) => {
		// 	console.log( e )
		// })

		// localStorage.clear();

		this.accessStore.callback(async (response) => {
			// console.log( response );
			this.setState({
				user: JSON.parse( response ),
			}, () => {
				Get_dashboard_info( this.state.user.id ).then((response) => {
					// console.log( response.data );
					this.setState({
						activities: response.data.user_dashboard.data,
						signup_tarrif: response.data.user_dashboard.unit_reward_signup,
						cars_tarrif: response.data.user_dashboard.unit_reward_cars,
					}, () => {
						let gain = 0;
						this.state.activities.map((item, i ) => {
							gain = gain + ( item.regcount * ( this.state.signup_tarrif ) ) + ( item.carcount * ( this.state.cars_tarrif ) );
							this.setState({
								gain: gain,
							});
						},() => {

						});
					});
				}).catch((e) => {
					console.log( e );
				});
			});
        });

		this.accessStore.init( 'user_sentinelle', "INIT_USER");
		this.timeout = '';
	}

	_copyToClipBoard() {
		var copyText = document.querySelector("#affiliateCode");
		clearTimeout( this.timeout );
  		copyText.select();
		document.execCommand("copy");

		this.setState({
            refcode_copied: true,
        }, () => {
            this.timeout = setTimeout( () => {
                this.setState({
                    refcode_copied: false,
                });
            }, 5000);
        });
	}

	_isObjEmpty(obj) {
		for (var prop in obj) {
		  if (obj.hasOwnProperty(prop)) return false;
		}
		return true;
	}

	render() {
		// console.log( (this.props.user_sentinelle) );
		return (
			<div className="dashboard">
				<div className="dashboard_content">
					<div className="dashboard_content_">
						{
							this.state.activities.length === 0 ?
								<div className="dashboard_content_noActivities">
									<div className="dashboard_content_noActivities_title1">
										<span>
											Bonjour { this.state.user.name ? this.state.user.name : '' }!
										</span>
									</div>
									<div className="dashboard_content_noActivities_title2">
										<span>
											Vous n’avez pas encore d’activité. 
										</span>
									</div>

									<div className="dashboard_content_noActivities_affiliateCode">
										<div
											className="dashboard_content_noActivities_affiliateCode_button"
											onClick={() => {
												this._copyToClipBoard();
											}}
											style={{
												background: this.state.refcode_copied ? " rgba(37, 174, 136, 0.25)" : "#DCDCDC"
											}}
										>
											<div className="dashboard_content_noActivities_affiliateCode_button_text">
												<span className="dashboard_content_noActivities_affiliateCode_button_text_top">
													Votre code d’affiliation
												</span>
												<input id="affiliateCode" className="dashboard_content_noActivities_affiliateCode_button_text_code" readOnly={true} type="text" contentEditable={false} value={ this.state.user.refcode ? this.state.user.refcode : '' }/>
											</div>
											<div>
												<svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M12.1429 16.625V18.1094C12.1429 18.6013 11.7351 19 11.2321 19H0.910714C0.407734 19 0 18.6013 0 18.1094V4.45312C0 3.96124 0.407734 3.5625 0.910714 3.5625H3.64286V14.5469C3.64286 15.6928 4.59611 16.625 5.76786 16.625H12.1429ZM12.1429 3.85938V0H5.76786C5.26488 0 4.85714 0.39874 4.85714 0.890625V14.5469C4.85714 15.0388 5.26488 15.4375 5.76786 15.4375H16.0893C16.5923 15.4375 17 15.0388 17 14.5469V4.75H13.0536C12.5527 4.75 12.1429 4.34922 12.1429 3.85938ZM16.7333 2.70791L14.231 0.260842C14.0602 0.0938283 13.8286 1.23499e-06 13.5871 0L13.3571 0V3.5625H17V3.33765C17 3.10146 16.9041 2.87493 16.7333 2.70791Z" fill="#646464"/>
												</svg>
											</div>
										</div>
									</div>

									<div className="dashboard_content_noActivities_clickToCpyText">
									{
										this.state.refcode_copied ?
											<span style={{color: "#2E8C72"}}>Code copié!</span>
										:
											<span>
												Cliquez sur le bouton pour copier
											</span>
									}
									</div>
								</div>
							:
							<div className="dashboard_content_activities">
								<div className="dashboard_content_activities_">
									<div className="dashboard_content_activities_header">
										<div className="dashboard_content_activities_header_item">
											<span>Date</span>
										</div>
										<div className="dashboard_content_activities_header_item inscriptions_item">
											<span>
												Inscriptions
											</span>
										</div>
										<div className="dashboard_content_activities_header_item inscriptions_item">
											<span>
												Vehicules
											</span>
										</div>
									</div>

									<div className="dashboard_content_activities_list">
										{
											this.state.activities.map((item, i) => (
												<div key={i} className="dashboard_content_activities_list_item">
													<div className="dashboard_content_activities_list_item_date">
														<span>
															{
																item.date
															}
														</span>
													</div>
													<div className="dashboard_content_activities_list_item_inscriptions">
														<span>
															{
																item.regcount
															}
														</span>
													</div>
													<div className="dashboard_content_activities_list_item_inscriptions">
														<span>
															{
																item.carcount
															}
														</span>
													</div>
												</div>
											))
										}
									</div>
								</div>

								<div className="dashboard_content_activities_gains">
									<div className="dashboard_content_activities_gains_">
										<span className="dashboard_content_activities_gains_text">
											Gain sur la période en cours
										</span>
										<span className="dashboard_content_activities_gains_solde">
											{ this.state.gain } FCFA
										</span>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		);

	}

}

Dashboard = connect(mapStateToProps, null)(Dashboard);
export default Dashboard;