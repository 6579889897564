/** Importation globale : */
import React from 'react';
// import { Link } from 'react-router-dom';

/** Importation du stylesheet : */
import './styles/confirmCode.style.css';

import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import AccessStore from '../store/accessStore';

/**Importation des images */

/**Importation des helpers */
import verify_code from '../helpers/verify_code.helper';
import Registered from '../helpers/registered.helper';
import Login from '../helpers/login.helper';

function mapStateToProps(state) {
    return {
        user_sentinelle : state.User.user_sentinelle,
    };

}


/**
 * Construction du screen.
 */
class ConfirmCode extends React.Component {

	constructor(props) {
		super(props);
		this.accessStore = new AccessStore(this.props.dispatch);

		this.state = {
			code: '',
			go_to_dashboard: false,
		}
	}

	componentDidMount() {
		// console.log( this.props.location.state.user );
	}
	async _verifyCode(code) {
		if (code !== '') {
			this.setState({
				isLoading: true,
			}, async () => {
				let data = {
					phone_number: this.props.location.state.user.phone_number,
					country_code: this.props.location.state.user.country_code,
					code: code,
				};

				verify_code(data).then((response) => {
					// console.log( response.data );
					// console.log( response )
					if (response.data.status === 200) {
						if (this.props.location.state.type === "createAccount") {
							// console.log( this.props.location.state.user );
							let user = {
								name: this.props.location.state.user.name,
								phone_number: "+" + this.props.location.state.user.country_code + this.props.location.state.user.phone_number,
								referenceable: true,
							};

							// console.log( user );
							Registered(user).then((response) => {
								// console.log( response.data );
								if (response.data.status === 201) {
									this.accessStore.setRedux("SET_USER", response.data.user);
									this.setState({
										isLoading: false,
										go_to_dashboard: true,
									});
								}

								else if (response.data.status === 200) {
									let user_login = {
										phone_number: "+" + this.props.location.state.user.country_code + this.props.location.state.user.phone_number,
										referenceable: true,
									};
									Login( user_login ).then((response) => {
										this.setState({
											isLoading: false,
										});
										// console.log( response.data );

										if (response.data.status === 404) {
											alert('', 'Aucun compte trouvé n\'est associé à ce numéro. Veuillez créer un compte pour continuer');
										}

										else {
											// let user = {
											// 	name: response.data.user.name,
											// 	phone_number: response.data.user.phone_number,
											// 	id: response.data.user.id,
											// 	referenceable: true,
											// };

											this.accessStore.setRedux("SET_USER", response.data.user);
											this.setState({
												go_to_dashboard: true,
											});
										}

									}).catch((e) => {
										// log.clog(e);
										this.setState({
											isLoading: false,
										});
										if (e.response.status === 404) {
											alert('', 'Aucun compte trouvé n\'est associé à ce numéro. Veuillez créer un compte pour continuer');
										}

										if (e.toString() === "Error: Network Error") {
											alert('', 'Un problème de connexion est survenu. Vérifiez que votre téléphone est bien connectés à Internet');
										}
									});
								}

								else {

									alert('', 'Un problème est survenu lors de la connexion  à la base de données. Veuillez reéssayer SVP!');
								}
							}).catch((e) => {
								console.log(e);
								this.setState({
									isLoading: false,
								});

								if (e.toString() === "Error: Network Error") {
									alert('', 'Un problème de connexion est survenu. Vérifiez que votre téléphone est bien connectés à Internet');
								}
							});
						}

						else if (this.props.location.state.type === "login") {
							// console.log( this.props.location.state.user );
							let user = {
								phone_number: "+" + this.props.location.state.user.country_code + this.props.location.state.user.phone_number,
								referenceable: true,
							};
							Login( user ).then((response) => {
								this.setState({
									isLoading: false,
								});
								// log.clog( response.data );

								if (response.data.status === 404) {
									alert('', 'Aucun compte trouvé n\'est associé à ce numéro. Veuillez créer un compte pour continuer');
								}

								else {
									// let user = {
									// 	name: response.data.user.name,
									// 	phone_number: response.data.user.phone_number,
									// 	id: response.data.user.id,
									// };

									this.accessStore.setRedux("SET_USER", response.data.user);
									this.setState({
										go_to_dashboard: true,
									});
								}

							}).catch((e) => {
								console.log(e);
								this.setState({
									isLoading: false,
								});
								if (e.response && e.response.status === 404) {
									alert('', 'Aucun compte trouvé n\'est associé à ce numéro. Veuillez créer un compte pour continuer');
								}

								if (e.toString() === "Error: Network Error") {
									alert('', 'Un problème de connexion est survenu. Vérifiez que votre téléphone est bien connectés à Internet');
								}
							})
						}
					}

					else {
						this.setState({
							isLoading: false,
						});

						alert('Erreur', 'Code incorrecte');
					}

				}).catch((e) => {
					console.log( e );
					if (e.toString() === "Error: Network Error") {
						alert('', 'Un problème de connexion est survenu. Vérifiez que votre téléphone est bien connectés à Internet');
					}
				});

			});
		}

		else {
			alert('Code non saisie', 'Veuiller saisir le code de vérification que vous avez reçu par SMS.')
		}
	}

	render() {
		return (
			!this.state.go_to_dashboard ?
				<div className="content">
					<div className="content_">
						<div className="content_top">
							<div className="content_top_icon">
							<svg width="35" height="35" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M38.6857 3.12227C38.4185 2.85508 37.9866 2.85508 37.7194 3.12227C37.4522 3.38947 37.4522 3.82135 37.7194 4.08855C40.2725 6.6416 40.2725 10.7958 37.7194 13.3495C37.4522 13.6167 37.4522 14.0486 37.7194 14.3158C37.8527 14.449 38.0276 14.516 38.2026 14.516C38.3775 14.516 38.5525 14.449 38.6857 14.3158C41.7718 11.2304 41.7718 6.20834 38.6857 3.12227Z" fill="#353D40"/>
								<path d="M35.8083 4.67763C35.5411 4.41044 35.1092 4.41044 34.842 4.67763C34.5748 4.94483 34.5748 5.37671 34.842 5.64391C35.6873 6.48923 36.1527 7.61541 36.1527 8.81403C36.1527 10.0127 35.6873 11.1382 34.842 11.9835C34.5748 12.2507 34.5748 12.6826 34.842 12.9498C34.9753 13.083 35.1502 13.15 35.3251 13.15C35.5001 13.15 35.675 13.083 35.8083 12.9498C36.9119 11.8468 37.5194 10.3776 37.5194 8.81403C37.5194 7.2505 36.9126 5.78058 35.8083 4.67763Z" fill="#353D40"/>
								<path d="M3.28083 4.08855C3.54803 3.82135 3.54803 3.38947 3.28083 3.12227C3.01363 2.85508 2.58175 2.85508 2.31455 3.12227C-0.771518 6.20834 -0.771518 11.2297 2.31455 14.3158C2.44781 14.449 2.62275 14.516 2.79769 14.516C2.97263 14.516 3.14757 14.449 3.28083 14.3158C3.54803 14.0486 3.54803 13.6167 3.28083 13.3495C0.727099 10.7964 0.727099 6.64228 3.28083 4.08855Z" fill="#353D40"/>
								<path d="M6.15838 4.48903C5.89118 4.22184 5.4593 4.22184 5.1921 4.48903C4.08847 5.59198 3.48096 7.06121 3.48096 8.62475C3.48096 10.1883 4.08847 11.6575 5.1921 12.7612C5.32536 12.8944 5.5003 12.9614 5.67524 12.9614C5.85018 12.9614 6.02512 12.8944 6.15838 12.7612C6.42557 12.494 6.42557 12.0621 6.15838 11.7949C5.31306 10.9496 4.84768 9.82337 4.84768 8.62475C4.84768 7.42613 5.31237 6.30063 6.15838 5.45531C6.42489 5.1888 6.42489 4.75623 6.15838 4.48903Z" fill="#353D40"/>
								<path d="M20.5 37.585C21.2549 37.585 21.8668 36.9731 21.8668 36.2183C21.8668 35.4635 21.2549 34.8516 20.5 34.8516C19.7452 34.8516 19.1333 35.4635 19.1333 36.2183C19.1333 36.9731 19.7452 37.585 20.5 37.585Z" fill="#353D40"/>
								<path d="M29.1075 0H11.8935C10.2336 0 8.8833 1.35101 8.8833 3.01022V37.9909C8.8833 39.6508 10.2336 41.0018 11.8935 41.0018H29.1068C30.7673 41.0018 32.1177 39.6508 32.1177 37.9916V3.01022C32.1177 1.35101 30.7673 0 29.1075 0ZM22.5506 2.05009H23.2339C23.6112 2.05009 23.9173 2.35556 23.9173 2.73346C23.9173 3.11136 23.6112 3.41682 23.2339 3.41682H22.5506C22.1734 3.41682 21.8672 3.11136 21.8672 2.73346C21.8672 2.35556 22.1734 2.05009 22.5506 2.05009ZM17.767 2.05009H20.5005C20.8777 2.05009 21.1839 2.35556 21.1839 2.73346C21.1839 3.11136 20.8777 3.41682 20.5005 3.41682H17.767C17.3898 3.41682 17.0837 3.11136 17.0837 2.73346C17.0837 2.35556 17.3898 2.05009 17.767 2.05009ZM20.5005 38.9517C18.993 38.9517 17.767 37.7258 17.767 36.2183C17.767 34.7108 18.993 33.4848 20.5005 33.4848C22.008 33.4848 23.2339 34.7108 23.2339 36.2183C23.2339 37.7258 22.008 38.9517 20.5005 38.9517ZM30.7509 31.4347H10.25V5.46691H30.7509V31.4347Z" fill="#353D40"/>
							</svg>

							</div>

							<div className="content_top_text">
								<span className="content_top_text_">
									Vérification
								</span><br />
								<span className="content_top_text_">
									du code
								</span>
							</div>
						</div>

						<div className="content_body">
							<div className="content_body_">
								<div className="content_body_input">
									<span className="content_body_input_label">
										Code de vérification
									</span>
									<input
										className="content_body_input_balise"
										type="number"
										placeholder="Code à 06 chiffres"
										onChange = {( e ) => {
											this.setState({
												code: e.target.value,
											});
										}}
									/>
								</div>

								<div className="content_body_input">
									<input
										className="content_body_input_balise submit"
										type="submit"
										value="Vérifier le code"
										onClick={() => {
											this._verifyCode( this.state.code );
										}}
									/>
								</div>

								<div className="content_body_input2">
									<input className="content_body_input_connection" type="submit" value="Renvoyer le code" />
								</div>
							</div>
						</div>
					</div>
				</div>
			:
				<Redirect to="/dashboard"/>
		);

	}

}

ConfirmCode = connect(mapStateToProps, null)(ConfirmCode);
export default ConfirmCode;