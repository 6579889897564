import axios from "axios";

function Registered(data) {
    // console.log(data);
    return (
        axios({
            method: 'post',
            url: "https://swiitch-bukar.herokuapp.com/api/user/reg",
            data: data,
        })
    )
}

export default Registered;