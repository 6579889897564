// Store/configureStore.js
import { createStore, combineReducers } from 'redux';
import User from '../store/reducers/user_reducer';

const MY_REDUCER = combineReducers({
    User,
});

const Store = createStore(MY_REDUCER);

export default Store;