import axios from "axios";

function Get_dashboard_info(id) {
    return (
        axios({
            method: 'get',
            url: "https://swiitch-bukar.herokuapp.com/api/user/dashboard",
            headers: {
                "X-Header-Userid": id
            }
        })
    )
}

export default Get_dashboard_info;