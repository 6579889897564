class AccessStore {
    constructor(dispatch) {
        this.dispatcher = dispatch;
    }

    _getSession(key) {
        return localStorage.getItem(key);
    }

    init(key, action) {
        let session = this._getSession(key);

        if (session !== null && session !== undefined) {
            const a = { type: action, value: session };
            this.dispatcher(a);
            //console.log( session );
        }

        if (this.callback !== undefined && this.callback != null) {
            this.callback(session);
        }
    }

    setRedux(action, value, callback = null) {
        const a = { type: action, value: value };
        this.dispatcher(a);
        if (callback != null) {
            return callback();
        }
    }

    callback(callback) {
        this.callback = callback;
    }
}

export default AccessStore;