/**Importation des actions du store */
import INIT_USER from '../store_actions/userActions/init_user.action';
import SET_USER from '../store_actions/userActions/set_user.action';
import LOG_OUT from '../store_actions/userActions/set_user.action';

const initialState = {
    user_sentinelle: {},
}

function setSession(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function RemoveAllSession() {
    localStorage.clear();
}

function User(state = initialState, action) {
    switch (action.type) {
        case 'INIT_USER':
            return INIT_USER(state, action.value);

        case 'SET_USER':
            setSession("user_sentinelle", action.value);
            return SET_USER(state, action.value);

        case 'LOG_OUT':
            RemoveAllSession();
            return LOG_OUT(state, {});

        default:
            return state
    }

}

export default User;