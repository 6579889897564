import axios from "axios";

function Login( data ) {
    return(
        axios({
            method: 'post',
            url:"https://swiitch-bukar.herokuapp.com/api/user-data",
            data: data,
        })
    )
}

export default Login;